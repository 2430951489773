<template>
  <div class="CardEvent">
    <div class="CardEvent-row columns" :style="{backgroundColor: designEvent.bgColorCardEvent}">
      <div class="CardEvent-colImage column is-3" :style="[ eventData.coverURL ? { backgroundImage: 'url(' + eventData.coverURL + ')'} : '']">
        <div class="CardEvent-mini">
          <div class="CardEvent-mini-top" :style="{ backgroundColor:  designEvent.colorHeadEvent, color: designEvent.colorTitleEvent }">
            EVENTO
          </div>
          <div class="CardEvent-mini-bottom">
            <iconCalendar color="#000" size="30px" class="icon"></iconCalendar>
            <div class="date">
              <div class="date-list">
                {{ parseInt(eventData.date) | moment('timezone', vendorTimezone, 'DD MMM') }}
              </div>
              <div class="date-year">
                 {{ parseInt(eventData.date) | moment('timezone', vendorTimezone, 'Y') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="CardEvent-colTextEvent column">
        <div class="title-content">
          <div class="card-title">
            <h4>{{ eventData.displayName }} </h4>
            <div class="chef">{{ eventData.chef }}</div>
          </div>
          <div class="price-box">
            <div class="price" v-if="eventData.purchaseCommission">{{ eventData.purchaseCommission | currency('$', 0) }}</div>
            <div class="sub-price" v-if="eventData.purchaseCommission">Anticipo por persona</div>
          </div>
        </div>
        <!-- <div class="content" v-html="this.$options.filters.truncate(eventData.description, 500)"></div> -->
        <div class="content" v-if="eventData.description" v-html="this.$options.filters.truncate(eventData.description, 500)"></div>
        <div class="buttons-box ">
            <button class="btn-padding btn-reservation" @click="goToEvent(eventData)" :style="{ backgroundColor:  designEvent.colorHeadEvent, color: designEvent.colorTitleEvent, border: '1px ' + designEvent.colorTitleEvent + ' solid' }">
              Conocer más
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// =========== Components ===========
import iconCalendar from '@/components/Icons/iconCalendar';
export default {
  name: 'cardEvent',
  props: ['eventData'],
  components: {
    iconCalendar
  },
  data () {
    return {
      people: this.eventData.minimumChargedPeople
    };
  },
  methods: {
    goToEvent (eventData) {
      window.location.href = '/events/' + eventData.id;
    }
  }
};
</script>
<style lang="scss" scoped>
.color-people {
  color: var(--colorHeadEvent);
}
.button-people {
  background-color: var(--colorHeadEvent);
  color: var(--colorContrastEvent);
}
.colorText-Event {
  color: var(--colorTextEvent);
}
.CardEvent {
  padding: 0 0 2rem 0;

  &-mini {
    display: flex;
    background: #fff;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    width: 115px;
    &-top {
      padding: 5px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      background: var(--bgColorStickPay);
      color: var(--colorStickPay);
    }
    &-bottom {
      padding: 10px;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 10px;
        height: 30px;
      }
      .date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        &-list {
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 14px;
        }
        &-year {
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          width: 100%;
        }
      }
    }
  }

  &-colTextEvent {
    color: #000;
    padding-top: 0px;

    .title-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-title {
        display: flex;
        flex-direction: column;
        h4 {
          font-weight: 700;
          font-size: 26px;
        }
      }
      .price-box {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        .price {
          font-weight: 700;
          font-size: 24px;
        }
        .sub-price {
          font-weight: 500;
          font-size: 13px;
          margin-top: -5px;
        }
      }
    }

    .content {
      font-family: unset;
      font-size: 14px;
      color: #000;
      padding: 20px 0;
      line-height: 19px;
      margin-bottom: 0;
    }

    .buttons-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-reservation {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 600;
        padding: 10px 20px;
        cursor: pointer;
        background: var(--bgColorStickPay);
        color: var(--colorStickPay);
      }
    }
  }
  &-row {
    margin: unset;
    padding: 15px;
    background: #e1dcd2;
  }
  &-colImage {
    min-height: 150px;
    background-size: cover;
    background-position: center center;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &-rowCard {
    max-width: 7em;
    background-color: white;
    border-radius: 6px;
    margin: unset;
  }
  &-rowCardDate {
    padding: 3px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
  }
  &-rowCardDateBody {
    margin: unset;
    padding: 5px 5px;
  }
  &-colTextEvent {
    @media (max-width: 768px) {
      padding: unset;
    }
  }
}
.background-white {
  background-color: #fff;
}
</style>
